<template>
  <div class="contents register wedding">
    <div class="title flexB">
      <h2>투어상담 관리</h2>
    </div>
    <div class="box one">
      <h3 class="mb20">상담내용</h3>
      <div class="inputBox">
        <!-- <div class="flexL">
          <p class="bold">
            카테고리
            <span>*</span>
          </p>
          <select class="searchCondition" v-model="tourType" disabled>
            <option value disabled>선택</option>
            <option value="planner">웨딩 플래너</option>
            <option value="collection">웨딩 컬렉션</option>
            <option value="MAKEUP">헤어&#38;메이크업/부케</option>
            <option value="HONEYMOON">허니문</option>
            <option value="VIDEO">웨딩영상</option>
            <option value="EVENT">주례&#38;사회/이벤트</option>
            <option value="GIFT">예물</option>
            <option value="HANBOK">한복/예단</option>
            <option value="PYEBAEK">폐백&#38;이바지/청첩장</option>
            <option value="BEAUTY">뷰티케어</option>
            <option value="INTERIOR">가전/가구/인테리어</option>
            <option value="CATERING">케이터링</option>
            <option value="HALL">웨딩홀</option>
            <option value="HOTAL">호텔</option>
            <option value="CATERING">케이터링</option>
          </select>
        </div> -->
        <div class="flexL">
          <p class="bold">투어 타입</p>
          <p class="mr10">
            {{
              tourType == "planner"
                ? "플래너 투어"
                : tourType == "weddingPlace"
                ? "웨딩장소"
                : tourType == "collection"
                ? "컬렉션"
                : ""
            }}
          </p>
          <!-- <router-link
            v-if="tourType == 'plannner'"
            class="basic"
            :to="`/admin/userDetail?id=${plannners[0]._id}`"
            ><span class="material-icons"> open_in_new </span></router-link
          >
          <router-link
            class="basic"
            v-else-if="tourType == 'collection'"
            :to="`/admin/collectionmanage/register?id=${collection._id}`"
            ><span class="material-icons"> open_in_new </span></router-link
          >
          <router-link
            class="basic"
            v-else-if="tourType == 'weddingPlace'"
            :to="`/admin/placemanage/register?id=${weddingPlace._id}`"
            ><span class="material-icons"> open_in_new </span></router-link
          > -->
        </div>
        <div class="flexL">
          <p class="bold">고객명</p>
          <p class="mr10">{{ user.username }}</p>
          <router-link class="basic" :to="`/admin/userDetail?id=${user._id}`"
            ><span class="material-icons"> open_in_new </span></router-link
          >
        </div>
        <div class="flexL">
          <p class="bold">전화번호</p>
          <p>{{ consult?.mobileNo }}</p>
        </div>
        <div class="flexL">
          <p class="bold">투어일정</p>
          <p>
            {{ moment(consult?.visitDate).format("YYYY년 MM월 DD일") }}
            {{ consult?.visitTime }}
          </p>
        </div>

        <div class="flexL">
          <p class="bold">결혼예정일</p>
          <p>{{ moment(consult?.weddingDate).format("YYYY년 MM월 DD일") }}</p>
        </div>

        <div class="flexL">
          <p class="bold">예식장</p>
          <p>{{ consult?.region }}</p>
        </div>
        <div class="flexL double" v-if="consult">
          <p class="bold">상담 내용</p>
          <textarea disabled v-model="consult.comment" />
        </div>
        <div class="flexL double" v-if="collection">
          <p class="bold">컬렉션 정보</p>
          <div class="goUrl">
            <p>{{ collection.title }}</p>
            <a
              class="basic"
              target="_blank"
              :href="`https://theplanner.co.kr/collection/detail/${collection._id}`"
            >
              <span class="material-icons"> open_in_new </span></a
            >
          </div>
        </div>
        <div class="flexL double" v-else-if="planners">
          <p class="bold">플래너 정보</p>
          <div class="goUrl">
            <p>{{ planners.plannerName }}</p>
            <!-- <a
              class="basic"
              target="_blank"
              :href="`https://theplanner.co.kr/collection/detail/${collection?._id}`"
            >
              <span class="material-icons"> open_in_new </span></a
            > -->
          </div>
        </div>
        <section>
          <div class="flexL" v-if="category == 'STUDIO'">
            <p class="bold">
              상품 종류
              <span>*</span>
            </p>
            <el-select
              v-model="studioType"
              multiple
              placeholder="선택"
              class="searchCondition"
            >
              <el-option key="웨딩촬영" label="웨딩촬영" value="웨딩촬영">
              </el-option>
              <el-option key="세미촬영" label="세미촬영" value="세미촬영">
              </el-option>
              <el-option key="본식스냅" label="본식스냅" value="본식스냅">
              </el-option>
              <el-option key="가봉스냅" label="가봉스냅" value="가봉스냅">
              </el-option>
              <el-option key="데이트스냅" label="데이트스냅" value="데이트스냅">
              </el-option>
              <el-option key="기타사진" label="기타사진" value="기타사진">
              </el-option>
            </el-select>
          </div>
          <div class="flexL" v-if="category == 'STUDIO'">
            <p class="bold">
              촬영자 수
              <span>*</span>
            </p>
            <el-select
              v-model="studioCount"
              multiple
              placeholder="선택"
              class="searchCondition"
            >
              <el-option key="1인" label="1인" value="1인"> </el-option>
              <el-option key="2인" label="2인" value="2인"> </el-option>
            </el-select>
          </div>
          <div class="flexL" v-if="category == 'STUDIO'">
            <p class="bold">
              상품 구성
              <span>*</span>
            </p>
            <el-select
              v-model="studioComprise"
              multiple
              placeholder="선택"
              class="searchCondition"
            >
              <el-option key="데이터" label="데이터" value="데이터">
              </el-option>
              <el-option key="인화물" label="인화물" value="인화물">
              </el-option>
              <el-option key="앨범" label="앨범" value="앨범"> </el-option>
              <el-option key="액자" label="액자" value="액자"> </el-option>
              <el-option
                key="본식스냅(원판)"
                label="본식스냅(원판)"
                value="본식스냅(원판)"
              >
              </el-option>
              <el-option
                key="본식스냅(스냅)"
                label="본식스냅(스냅)"
                value="본식스냅(스냅)"
              >
              </el-option>
              <el-option
                key="본식스냅(원판+스냅)"
                label="본식스냅(원판+스냅)"
                value="본식스냅(원판+스냅)"
              >
              </el-option>
            </el-select>
          </div>
          <div class="flexL" v-if="category == 'STUDIO'">
            <p class="bold">
              스타일
              <span>*</span>
            </p>
            <el-select
              v-model="studioStyle"
              multiple
              placeholder="선택"
              class="searchCondition"
            >
              <el-option key="인물중심" label="인물중심" value="인물중심">
              </el-option>
              <el-option key="배경다양" label="배경다양" value="배경다양">
              </el-option>
              <el-option key="인물+배경" label="인물+배경" value="인물+배경">
              </el-option>
            </el-select>
          </div>
          <div class="flexL" v-if="category == 'STUDIO'">
            <p class="bold">
              촬영 종류
              <span>*</span>
            </p>
            <el-select
              v-model="studioScene"
              multiple
              placeholder="선택"
              class="searchCondition"
            >
              <el-option key="웨딩촬영" label="웨딩촬영" value="웨딩촬영">
              </el-option>
              <el-option key="본식촬영" label="본식촬영" value="본식촬영">
              </el-option>
              <el-option key="한옥촬영" label="한옥촬영" value="한옥촬영">
              </el-option>
              <el-option key="야간촬영" label="야간촬영" value="야간촬영">
              </el-option>
              <el-option key="로드촬영" label="로드촬영" value="로드촬영">
              </el-option>
              <el-option key="가든촬영" label="가든촬영" value="가든촬영">
              </el-option>
              <el-option key="흑백촬영" label="흑백촬영" value="흑백촬영">
              </el-option>
              <el-option key="한복촬영" label="한복촬영" value="한복촬영">
              </el-option>
              <el-option key="캐쥬얼촬영" label="캐쥬얼촬영" value="캐쥬얼촬영">
              </el-option>
              <el-option
                key="반려동물촬영"
                label="반려동물촬영"
                value="반려동물촬영"
              >
              </el-option>
            </el-select>
          </div>
          <div class="flexL" v-if="category == 'DRESS'">
            <p class="bold">
              상품 종류
              <span>*</span>
            </p>
            <el-select
              v-model="dressType"
              multiple
              placeholder="선택"
              class="searchCondition"
            >
              <el-option
                key="본식웨딩드레스"
                label="본식웨딩드레스"
                value="본식웨딩드레스"
              >
              </el-option>
              <el-option key="세미드레스" label="세미드레스" value="세미드레스">
              </el-option>
              <el-option key="예복" label="예복" value="예복"> </el-option>
            </el-select>
          </div>
          <div class="flexL" v-if="category == 'DRESS'">
            <p class="bold">
              스타일
              <span>*</span>
            </p>
            <el-select
              v-model="dressStyle"
              multiple
              placeholder="선택"
              class="searchCondition"
            >
              <el-option key="심플한" label="심플한" value="심플한">
              </el-option>
              <el-option key="화려한" label="화려한" value="화려한">
              </el-option>
            </el-select>
          </div>
          <div class="flexL" v-if="category == 'DRESS' && dressType != '예복'">
            <p class="bold">
              드레스 강점
              <span>*</span>
            </p>
            <el-select
              v-model="dressPoint"
              multiple
              placeholder="선택"
              class="searchCondition"
            >
              <el-option key="실크" label="실크" value="실크"> </el-option>
              <el-option key="레이스" label="레이스" value="레이스">
              </el-option>
              <el-option key="비즈" label="비즈" value="비즈"> </el-option>
              <el-option key="반팔" label="반팔" value="반팔"> </el-option>
              <el-option key="벨라인" label="벨라인" value="벨라인">
              </el-option>
              <el-option
                key="머메이드라인"
                label="머메이드라인"
                value="머메이드라인"
              >
              </el-option>
              <el-option key="A라인" label="A라인" value="A라인"> </el-option>
            </el-select>
          </div>
          <div class="flexL" v-if="category == 'DRESS' && dressType != '예복'">
            <p class="bold">
              사이즈
              <span>*</span>
            </p>
            <el-select
              v-model="dressSize"
              multiple
              placeholder="선택"
              class="searchCondition"
            >
              <el-option key="44" label="44" value="44"> </el-option>
              <el-option key="55" label="55" value="55"> </el-option>
              <el-option key="66" label="66" value="66"> </el-option>
              <el-option key="77" label="77" value="77"> </el-option>
              <el-option key="88이상" label="88이상" value="88이상">
              </el-option>
            </el-select>
          </div>
          <div class="flexL" v-if="category == 'DRESS'">
            <p class="bold">
              제작 구분
              <span>*</span>
            </p>
            <el-select
              v-model="dressMade"
              multiple
              placeholder="선택"
              class="searchCondition"
            >
              <el-option key="국내" label="국내" value="국내"> </el-option>
              <el-option key="수입" label="수입" value="수입"> </el-option>
              <el-option key="국내+수입" label="국내+수입" value="국내+수입">
              </el-option>
            </el-select>
          </div>

          <!-- 헤어&메이크업/부케 -->
          <!-- 헤어&메이크업/부케 -->
          <!-- 헤어&메이크업/부케 -->
          <div class="flexL" v-if="category == 'MAKEUP'">
            <p class="bold">
              상품 종류
              <span>*</span>
            </p>
            <el-select
              v-model="makeupType"
              multiple
              placeholder="선택"
              class="searchCondition"
            >
              <el-option
                key="신랑,신부 헤어&메이크업"
                label="신랑,신부 헤어&메이크업"
                value="신랑, 신부 헤어&메이크업"
              >
              </el-option>
              <el-option
                key="혼주메이크업"
                label="혼주메이크업"
                value="혼주메이크업"
              >
              </el-option>
              <el-option key="부케" label="부케" value="부케"> </el-option>
            </el-select>
          </div>

          <!-- 허니문 -->
          <!-- 허니문 -->
          <!-- 허니문 -->
          <div class="flexL" v-if="category == 'HONEYMOON'">
            <p class="bold">
              상품 종류
              <span>*</span>
            </p>
            <el-select
              v-model="honeymoonType"
              multiple
              placeholder="선택"
              class="searchCondition"
            >
              <el-option key="휴양" label="휴양" value="휴양"> </el-option>
              <el-option key="관광" label="관광" value="관광"> </el-option>
              <el-option key="휴양+관광" label="휴양+관광" value="휴양+관광">
              </el-option>
            </el-select>
          </div>

          <!-- 웨딩 영상 -->
          <!-- 웨딩 영상 -->
          <!-- 웨딩 영상 -->
          <div class="flexL" v-if="category == 'VIDEO'">
            <p class="bold">
              상품 종류
              <span>*</span>
            </p>
            <el-select
              v-model="videoType"
              multiple
              placeholder="선택"
              class="searchCondition"
            >
              <el-option key="본식영상" label="본식영상" value="본식영상">
              </el-option>
              <el-option key="리허설영상" label="리허설영상" value="리허설영상">
              </el-option>
              <el-option key="식전영상" label="식전영상" value="식전영상">
              </el-option>
              <el-option key="식중영상" label="식중영상" value="식중영상">
              </el-option>
              <el-option key="기타" label="기타" value="기타"> </el-option>
            </el-select>
          </div>

          <!-- 주례&사회/이벤트 -->
          <!-- 주례&사회/이벤트 -->
          <!-- 주례&사회/이벤트 -->
          <div class="flexL" v-if="category == 'EVENT'">
            <p class="bold">
              상품 종류
              <span>*</span>
            </p>
            <el-select
              v-model="eventType"
              multiple
              placeholder="선택"
              class="searchCondition"
            >
              <el-option
                key="주례&사회자"
                label="주례&사회자"
                value="주례&사회자"
              >
              </el-option>
              <el-option key="이벤트" label="이벤트" value="이벤트">
              </el-option>
            </el-select>
          </div>

          <!-- 예물 -->
          <!-- 예물 -->
          <!-- 예물 -->
          <div class="flexL" v-if="category == 'GIFT'">
            <p class="bold">
              상품 종류
              <span>*</span>
            </p>
            <el-select
              v-model="giftType"
              multiple
              placeholder="선택"
              class="searchCondition"
            >
              <el-option key="예물" label="예물" value="예물"> </el-option>
            </el-select>
          </div>

          <div class="flexL" v-if="category == 'HANBOK'">
            <p class="bold">
              상품 종류
              <span>*</span>
            </p>
            <el-select
              v-model="hanbokType"
              multiple
              placeholder="선택"
              class="searchCondition"
            >
              <el-option key="한복" label="한복" value="한복"> </el-option>
              <el-option key="예단" label="예단" value="예단"> </el-option>
            </el-select>
          </div>

          <div class="flexL" v-if="category == 'PYEBAEK'">
            <p class="bold">
              상품 종류
              <span>*</span>
            </p>
            <el-select
              v-model="pyebaekType"
              multiple
              placeholder="선택"
              class="searchCondition"
            >
              <el-option
                key="폐백&이바지"
                label="폐백&이바지"
                value="폐백&이바지"
              >
              </el-option>
              <el-option key="청첩장" label="청첩장" value="청첩장">
              </el-option>
            </el-select>
          </div>

          <div class="flexL" v-if="category == 'BEAUTY'">
            <p class="bold">
              상품 종류
              <span>*</span>
            </p>
            <el-select
              v-model="beautyType"
              multiple
              placeholder="선택"
              class="searchCondition"
            >
              <el-option key="뷰티케어" label="뷰티케어" value="뷰티케어">
              </el-option>
            </el-select>
          </div>
          <div class="flexL" v-if="category == 'INTERIOR'">
            <p class="bold">
              상품 종류
              <span>*</span>
            </p>
            <el-select
              v-model="interiorType"
              multiple
              placeholder="선택"
              class="searchCondition"
            >
              <el-option key="가전" label="가전" value="가전"></el-option>
              <el-option key="가구" label="가구" value="가구"> </el-option>
              <el-option key="인테리어" label="인테리어" value="인테리어">
              </el-option>
              <el-option key="기타" label="기타" value="기타"> </el-option>
            </el-select>
          </div>

          <div class="flexL" v-if="category == 'CATERING'">
            <p class="bold">
              상품 종류
              <span>*</span>
            </p>
            <el-select
              v-model="cateringType"
              multiple
              placeholder="선택"
              class="searchCondition"
            >
              <el-option key="케이터링" label="케이터링" value="케이터링">
              </el-option>
            </el-select>
          </div>
        </section>

        <!-- <div class="flexL double">
          <p class="bold">유튜브 url</p>
          <input
            type="text"
            v-model="youtube"
            placeholder="유튜브에서 공유 / 퍼가기에 iframe 소스를 입력해 주세요."
          />
        </div> -->
        <!-- <div v-if="editMode"> -->
        <!-- <div class="flexL">
          <p class="bold">상품 정보</p>
          <button class="register" @click="handleCollectionModal(null, null)">
            등록하기
          </button>
        </div>
        <div class="flexL">
          <p class="bold">상품 갤러리</p>
          <button class="register" @click="handleCollectionModal2(null, null)">
            등록하기
          </button>
        </div> -->

        <!-- <div class="flexL">
          <p class="bold">
            대표사진
            <span>*</span>
          </p>
          <div class="imgBox">
            <ul>
              <li v-if="thumbnail">
                <img :src="thumbnail" alt="업로드이미지" />
                <a
                  class="cancel"
                  @click="handleDeleteFile(thumbnail, 'thumbnail')"
                >
                  <img
                    src="@/assets/images/icon_svg/ic_delete_wh.svg"
                    alt="icon"
                  />
                </a>
              </li>
              <li v-else>
                <label for="imgUpload">
                  <span class="material-icons addBtn">add</span>
                </label>
                <input
                  type="file"
                  ref="thumbnail"
                  @change="sendFile('thumbnail')"
                  id="imgUpload"
                  accept="image/*"
                />
              </li>
            </ul>
            <em class="notice"
              >대표사진은 3:2 비율로 업로드 됩니다. ex) 500 * 333</em
            >
          </div>
        </div> -->
        <!-- <div class="flexL">
          <p class="bold">상세이미지</p>
          <div class="imgBox">
            <ul>
              <li>
                <label for="imgUpload">
                  <span class="material-icons addBtn">add</span>
                </label>
                <input
                  type="file"
                  ref="images"
                  @change="sendFile('images')"
                  id="imgUpload"
                  accept="image/*"
                />
              </li>
              <li v-for="(image, i) in images" :key="i">
                <img :src="image" alt="업로드이미지" />
                <a class="cancel" @click="handleDeleteFile(image, 'images')">
                  <img
                    src="@/assets/images/icon_svg/ic_delete_wh.svg"
                    alt="icon"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div> -->
      </div>

      <!-- <div class="buttonWrap">
        <router-link to="/admin/tour/manage">목록</router-link>
        <button class="basic" @click="submit">
          {{ editMode == true ? "수정" : "등록" }}
        </button>
        <button v-if="editMode" class="line" @click="handleDelete">삭제</button>
      </div> -->
    </div>
    <!-- <div class="box one">
      <h2 class="bold">상품 가격 정보 입력</h2>

      <div class="flexL">
        <p class="bold">추가상품</p>
        <div>
          <input type="text" placeholder="추가상품명" />
          <input type="number" placeholder="재고 (숫자만 입력)" />
          <input type="number" placeholder="추가상품가 (숫자만 입력)" />
          <button class="basic">목록으로 적용</button>
        </div>
      </div>
      <div class="flexL optionTable">
        <table>
          <colgroup>
            <col style="width: 10%" />
            <col style="width: 10%" />
            <col style="width: 30%" />
            <col style="width: 10%" />
            <col style="width: 10%" />
            <col style="width: 10%" />
            <col style="width: 10%" />
          </colgroup>
          <tr>
            <th>NO</th>
            <th>종류</th>
            <th>상품</th>
            <th>내용</th>
            <th>공급가</th>
            <th>판매가</th>
            <th>리베이트</th>
            <th>수정/삭제</th>
          </tr>
          <tr>
            <td>1</td>
            <td>
              <el-select placeholder="선택">
                <el-option
                  key="리허설"
                  label="리허설"
                  value="리허설"
                ></el-option>
              </el-select>
              <span></span>
            </td>
            <td>
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <button class="table mr5">확인</button>
              <button class="table">삭제</button>
            </td>
          </tr>
        </table>
      </div>
    </div> -->
    <section class="box one">
      <div class="inputBox">
        <div class="flexL double">
          <p class="bold">투어 상담</p>
          <textarea v-model="content" />
        </div>
        <div class="flexL">
          <p class="bold">계약진행</p>
          <p class="mr10">{{ status == "contract" ? "계약완료" : "미계약" }}</p>
          <router-link
            class="basic"
            :to="`/admin/userDetail?id=${user._id}&tab=history`"
            ><span class="material-icons"> open_in_new </span></router-link
          >
        </div>
        <div class="flexL">
          <p class="bold">상담 스탬프</p>
          <button
            class="basic2"
            v-if="!consultStamp"
            :class="{ delete: consultStamp ? true : false }"
            @click="handleConsultStamp()"
          >
            스탬프 날인
          </button>
          <div v-else class="stamp">
            <p>날인완료</p>
            <span style="padding-left: 50px">{{
              moment(consultStampCreatedAt).format("YYYY.MM.DD HH:mm")
            }}</span>
          </div>
        </div>
        <div class="flexL">
          <p class="bold">계약 스탬프</p>
          <button
            class="basic2"
            v-show="!contractStamp"
            :class="{ delete: contractStamp ? true : false }"
            @click="handleContractStamp()"
          >
            스탬프 날인
          </button>
          <div v-show="contractStamp" class="stamp">
            <p>날인완료</p>
            <span style="padding-left: 50px">{{
              moment(contractStampCreatedAt).format("YYYY.MM.DD HH:mm")
            }}</span>
          </div>
        </div>
      </div>
      <div class="buttonWrap">
        <router-link to="/admin/tour/manage">목록</router-link>
        <button class="basic" @click="submit">
          {{ editMode == true ? "수정" : "등록" }}
        </button>
        <!-- <button v-if="editMode" class="line" @click="handleDelete">삭제</button> -->
      </div>
    </section>

    <transition name="fade">
      <div class="dim" v-if="modalVisible">
        <CollectionModal
          :select="select"
          @close="close"
          @updateData="updateData"
        />
        <div class="dimbg" @click="close"></div>
      </div>
    </transition>
    <transition name="fade">
      <div class="dim" v-if="modalVisible2">
        <CollectionModal2
          :select="select"
          @close="close"
          @updateData="updateData"
        />
        <div class="dimbg" @click="close"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import moment from "moment";
import {
  createTour,
  fetchTourDetail,
  updateTour,
  updateTourStamp,
} from "@/api/admin";
import CollectionModal from "@/components/pop/CollectionModal";
import CollectionModal2 from "@/components/pop/CollectionModal2";
import { format } from "@/mixins/format";

export default {
  mixins: [format],
  components: { CollectionModal, CollectionModal2 },

  data() {
    return {
      editMode: false,
      moment: moment,
      modalVisible: false,
      modalVisible2: false,
      collectionVisible: false,
      collectionVisible2: false,
      planners: [],
      consult: "",
      title: "",
      category: "",
      tourId: "",
      select: null,
      index: null,
      products: [],
      gallerys: [],
      type: "",
      user: "",
      includingVat: "on",
      tourType: "",
      status: "",
      content: "",
      consultStamp: false,
      contractStamp: false,
      consultStampCreatedAt: "",
      contractStampCreatedAt: "",
      createdAt: "",
      collection: "",
      weddingPlace: "",
      planner: "",
    };
  },
  watch: {
    price: function (newValue) {
      const result = newValue
        .toString()
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.price = result;
    },
  },

  created() {
    this.$store.dispatch("SET_NAVBAR", "13-1");
  },
  mounted() {
    if (this.$route.query.id) {
      this.editMode = true;
      this.tourId = this.$route.query.id;
      this.getTour();
    }
  },
  methods: {
    handleCollectionModal(data, index) {
      if (data != null && index != null) {
        this.select = data;
        this.index = index;
      }
      this.type = "product";
      this.modalVisible = true;
      this.collectionVisible = true;
    },
    handleCollectionModal2(data, index) {
      if (data != null && index != null) {
        this.select = data;
        this.index = index;
      }
      this.type = "gallery";
      this.modalVisible2 = true;
      this.collectionVisible2 = true;
    },
    itemDelete(index, type) {
      if (type == "product") {
        this.products.splice(index, 1);
      } else if (type == "gallery") {
        this.gallerys.splice(index, 1);
      }
    },
    close() {
      this.index = null;
      this.select = null;
      this.modalVisible = false;
      this.collectionVisible = false;
      this.modalVisible2 = false;
      this.collectionVisible2 = false;
    },
    updateData(data) {
      if (this.type == "product") {
        if (this.index != null) {
          this.products.splice(this.index, 1, data);
        } else {
          this.products.push(data);
        }
      } else if (this.type == "gallery") {
        if (this.index != null) {
          this.gallerys.splice(this.index, 1, data);
        } else {
          this.gallerys.push(data);
        }
      }
    },
    uncomma(str) {
      str = String(str);
      return str.replace(/[^\d]+/g, "");
    },
    getTour() {
      fetchTourDetail(this.tourId).then((res) => {
        if (res.data.status == 200) {
          this.tourType = res.data.data.tourType;
          this.user = res.data.data.user;
          // this.planner = res.data.data.planner;
          this.title = res.data.data.title;
          this.collection = res.data.data.collectionId;
          this.weddingPlace = res.data.data.weddingPlace;
          this.planners = res.data.data.planners;
          this.consult = res.data.data.consultId;
          this.status = res.data.data.status;
          this.content = res.data.data.content;
          this.consultStamp = res.data.data.consultStamp;
          this.contractStamp = res.data.data.contractStamp;
          this.consultStampCreatedAt = res.data.data.consultStampCreatedAt;
          this.contractStampCreatedAt = res.data.data.contractStampCreatedAt;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },

    openAlert(msg) {
      this.$alert(msg, {
        confirmButtonText: "확인",
        center: true,
      });
    },
    async handleConsultStamp() {
      if (this.consultStamp == true) {
        return alert("이미 날인을 하였습니다.");
      }
      let result = confirm(
        "상담 스탬프 날인을 진행 하시겠습니까? 날인 후에는 변경 할수 없습니다."
      );
      if (!result) {
        return;
      }
      let data = {
        tourId: this.tourId,
        userId: this.user._id,
        consultStamp: true,
        consultStampCreatedAt: Date.now(),
      };
      updateTourStamp(data).then((res) => {
        if (res.data.status == 200) {
          this.getTour();
          this.$alert("스탬프가 반영 되었습니다.", {
            confirmButtonText: "확인",
            center: true,
          });
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    async handleContractStamp() {
      if (this.contractStamp == true) {
        return alert("이미 날인을 하였습니다.");
      }
      let result = confirm(
        "계약 스탬프 날인을 진행 하시겠습니까? 날인 후에는 변경 할수 없습니다."
      );
      if (!result) {
        return;
      }
      let data = {
        tourId: this.tourId,
        userId: this.user._id,
        contractStamp: true,
        contractStampCreatedAt: Date.now(),
      };
      updateTourStamp(data).then((res) => {
        if (res.data.status == 200) {
          this.getTour();
          this.$alert("스탬프가 반영 되었습니다.", {
            confirmButtonText: "확인",
            center: true,
          });
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    submit() {
      let data = {
        tourId: this.tourId,
        tourType: this.tourType,
        content: this.content,
        consultStamp: this.consultStamp,
        contractStamp: this.contractStamp,
        consultStampCreatedAt: this.consultStampCreatedAt,
        contractStampCreatedAt: this.contractStampCreatedAt,
      };

      if (this.editMode) {
        updateTour(data).then((res) => {
          if (res.data.status == 200) {
            this.$alert("스탬프 투어 정보가 반영 되었습니다.", {
              confirmButtonText: "확인",
              center: true,
            }).then(() => {
              this.$router.push("/admin/tour/manage");
            });
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      } else {
        createTour(data).then((res) => {
          if (res.data.status == 200) {
            this.$alert("웨딩 컬렉션이 등록이 완료 되었습니다.", {
              confirmButtonText: "확인",
              center: true,
            }).then(() => {
              this.$router.push("/admin/tour/manage");
            });
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      }
    },
  },
};
</script>
